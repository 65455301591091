<template>
    <v-app>
        <HeaderApp
          :padding="showSidebar"
          :visible="userLogged"
        />
        <v-layout
          fill-height
        >
            <Sidebar
              v-if="showSidebar && userLogged"
              :authentication="authentication"
            />
            <v-main
              :class="showSidebar && userLogged ? 'cb-v-main':''"
            >
                <LoadingContainer
                  v-if="loadingContent"
                ></LoadingContainer>
                <router-view/>
                <v-footer
                  v-if="!showSidebar && userLogged"
                  class="mt-4"
                  color="white"
                  padless
                >
                    <v-row
                      :class="showSidebar && userLogged ? 'pl-12':''"
                      class="py-2"
                      justify="center"
                    >
                        <v-img
                          max-width="100px"
                          src="./assets/logo-certysign.png"
                          width="100px"
                        />
                    </v-row>
                </v-footer>
            </v-main>
        </v-layout>
        <SessionExpirationDialog/>
        <DialogMessage/>

        <right-sidebar-preview
          v-if="sidebarOpen"
          v-model="sidebarOpen"
          :item="sidebarItem"
          @openInNewTab="openSidebarItemNewTab"
        />
    </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import Sidebar from '@/components/global_app/Sidebar'
import vuex_global from '@/store/modules/sections/vuex_global'
import vuex_authentication from '@/store/modules/vuex_authentication'
import SessionExpirationDialog from '@/components/global_app/SessionExpirationDialog'
import DialogMessage from '@/components/global_app/DialogMessage'
import store from '@/store'
import HeaderApp from '@/components/global_app/HeaderApp.vue'
import LoadingContainer from '@/components/repository/LoadingContainer'
import service_user from '@/services/service_user'
import RightSidebarPreview from '@/components/global_app/rightSidebarPreview.vue'
import {bus} from './main'
import service_instances from '@/services/service_instances'
import service_templates from '@/services/service_templates'
import helpers from '@/plugins/helpers'
import service_processes from '@/services/service_processes'
import service_masteraccounts from '@/services/service_masteraccounts'

/**
 * @description Raíz
 */
export default {
    name: 'App',
    components: {
        RightSidebarPreview,
        LoadingContainer,
        HeaderApp,
        DialogMessage,
        SessionExpirationDialog,
        Sidebar
    },
    data() {
        return {
            sidebarItem: {},
            sidebarOpen: false,
        }
    },
    computed: {
        ...mapGetters({
            authentication: [vuex_authentication.constants.NAMESPACE] + '/' + [vuex_authentication.constants.G_LOGIN_DATA],
            sessionExpired: `${vuex_authentication.constants.NAMESPACE}/${vuex_authentication.constants.G_SESSION_EXPIRED}`
        }),
        loadingContent() {
            return this.$store.state[vuex_global.constants.NAMESPACE][vuex_global.constants.S_LOADING_DATA]
        },

        activeProfile: {
            get() {
                return this.$store.state[vuex_global.constants.NAMESPACE][vuex_global.constants.S_ACTIVE_PROFILE]
            },
            set(val) {
                store.dispatch(vuex_global.constants.NAMESPACE + '/' + vuex_global.constants.A_SET_ACTIVE_PROFILE, val)
            },
        },
        showSidebar() {
            let routes = this.$router.options.routes.filter(
              (r) =>
                r.meta.navigable && service_user.checkGroupPermission(r.meta.perm)
            )
            return this.userLogged && routes.length != 1
        },
        userLogged() {
            return this.authentication.username != null && this.$route.name != 'login'
        }
    },
    created() {
        bus.$on('showSidebarPreview', (item) => {
            this.sidebarItem = item
            this.sidebarOpen = true
        })
    },
    beforeDestroy() {
        bus.$destroy('showSidebarPreview')
    },
    methods: {
        openSidebarItemNewTab(item) {
            switch (item.type) {
                case 'instance':
                    service_instances.openInstance(item.code)
                    break
                case 'template':
                    service_templates.openTemplate(item.code)
                    break
                case 'process':
                    service_processes.openProcess(item.code, item.name)
                    break
                case 'masteraccount':
                    service_masteraccounts.openMasterAccount(item.code, item.name)
                    break
                default:
                    helpers.setAlertMessage(1, 'No se puede acceder a la entidad')
            }
        }
    }
}
</script>

<style lang="scss">
@import "./assets/styles/App.scss";
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700;800&display=swap');

$font-family: 'Roboto';


html {
    overflow-y: overlay !important;
    overflow: auto;
}

.v-application {
    position: relative;

    [class*="text-"] {
        font-family: $font-family, sans-serif !important;
    }


    .title {
        font-family: $font-family, sans-serif !important;
        line-height: 20px !important;
    }

    font-family: $font-family, sans-serif !important;

    .roboto {
        font-family: 'Roboto', sans-serif !important;
    }

}

h1, span, p {
    font-size: 13px !important;
    line-height: 14px;
}

.login_container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.v-input {
    font-size: 14px !important;
}

.v-list-item {
    min-height: auto !important;
    font-size: 14px !important;
}

button {
    text-transform: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-grey {
    background-color: #fafafa;
}

.bg-dark-grey {
    background-color: #f1f1f1;
}

.w-100 {
    width: 100% !important;
}

.w-85 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
}

.w-60 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
}

.w-40 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
}

.w-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 15%;
    flex: 0 0 15%;
    max-width: 15%;
}

.v-list {
    background: transparent !important;
}

@media (min-width: 960px) {
    .cb-v-main {
        padding-left: 200px !important;
    }
    .cb-toolbar-padding {
        padding-left: 200px;
    }

}


/*********	MENÚ Y HEADER	*********/


.border-radius-100 {
    border-radius: 100px;
}

.compact-mode {
    -moz-transform: scale(0.8, 0.8);
    zoom: 0.8;
    zoom: 80%;
}

/*use text fill color to cover font color*/
</style>
