<template>
    <v-row
      :id="'data-table-footer-'+ model"
      class="ma-0 justify-space-between px-2 cb-data-table-footer"
      dense
    >
        <v-col
          class="my-auto pa-0"
          cols="3"
        >
            <v-row>
                <a
                  :id="'data-table-footer-'+ model + '-refresh'"
                  class="mr-2 my-auto"
                  title="Refrescar resultados"
                  @click="refreshData()"
                >
                    <v-icon
                      class="ma-0"
                      color="accent"
                      style="font-size: 20px !important;"
                    >refresh
                    </v-icon>

                </a>
                <span class="my-auto">
          <span
            :id="'data-table-footer-'+ model + '-title'"
            class=" my-auto"
          >
            <strong :id="'data-table-footer-'+ model + '-total-items'">{{
                    helpers.numberWithCommas(totalItems)
                }}</strong>
            total
          </span>
        </span>
                <span class="mx-1 my-auto">|</span>
                <span class="my-auto">
            <span class="bg_dark--text ">
                            <strong :id="'data-table-footer-'+ model + '-items-per-page'">
                {{helpers.numberWithCommas(itemsPerPage)}}
              </strong> p.p.
            </span>
        </span>

            </v-row>
        </v-col>
        <v-col
          class="my-auto pa-0"
          cols="6"
        >
            <v-pagination
              v-if="pageCount != null && pageCount > 0"
              :id="'data-table-footer-'+ model + '-pagination'"
              :length="pageCount"
              :total-visible="5"
              :value="page"
              class="cb-pagination"
              color="none"
              @input="changePage($event)"
            >
            </v-pagination>
        </v-col>
        <v-col
          class="text-right my-auto pa-0"
          cols="3"
        >
            <v-row class="justify-end">
      <span
        v-if="resultTime != null"
        class="my-auto"
      >
        <v-icon
          class="mr-1"
          small
        >schedule</v-icon> <span
        :id="'data-table-footer-'+ model + '-time'"
        class="my-auto"
      >
        {{(resultTime).toFixed(4)}}s
      </span>
      </span>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import helpers from '@/plugins/helpers'

export default {
    name: 'DataTableFooter',
    props: {
        /**
         * Página actual
         */
        page: [String, Number],
        /**
         * Número total de elementos de la tabla
         */
        totalItems: [String, Number],
        /**
         * Nombre de los elementos a mostrar
         */
        model: String,
        /**
         * Tiempo de búsqueda (server side)
         */
        resultTime: {
            type: Number,
            default: null
        },
        /**
         * Elementos por página a mostrar
         */
        itemsPerPage: [String, Number]
    },
    data() {
        return {
            helpers,
        }
    },
    computed: {
        /**
         * Devuelve el cálculo de páginas que se deben mostrar
         * @return {number}
         * @public as
         */
        pageCount() {
            return Math.ceil(this.totalItems / this.itemsPerPage)
        }
    },
    methods: {
        /**
         * Se lanza cuando hay un cambio de página
         * @param page
         * @public
         */
        changePage(page) {
            this.$emit(this.$events.DATA_TABLE_CHANGE_PAGE, page)
        },
        /**
         * Se lanza cuando se hace click en el botón de refrescar
         * @public
         */
        refreshData() {
            this.$emit(this.$events.DATA_TABLE_REFRESH)
        }
    },
}
</script>

<style lang="scss">
@import "../../../../assets/styles/resources/colors";

.cb-data-table-footer {
    background-color: $color_app_contrast_low;
    border: solid 1px $color_app_contrast_medium_low;
    border-top: none;
}

.cb-data-table-footer button {
    box-shadow: none;
    border: solid 1px $color_app_contrast_high;
}

.cb-data-table-footer a {
    padding: 4px;
}

.cb-data-table-footer a:hover {
    background-color: $color_app_contrast_medium_low;
}

.cb-data-table-footer button:disabled {
    box-shadow: none;
    border: solid 1px $color_app_contrast_low;
}

.cb-data-table-footer .cb-pagination .v-pagination__item {
    border: none;
    color: $color_app_contrast_high !important;
    background-color: transparent;
}

.cb-data-table-footer .cb-pagination .v-pagination__item:hover {
    background-color: $color_app_contrast_medium_low !important;
}

.cb-data-table-footer .cb-pagination .v-pagination__navigation {
    border: none;
    background-color: transparent;
}

.cb-data-table-footer .cb-pagination .v-pagination__navigation:hover {
    background-color: $color_app_contrast_medium_low !important;
}

.cb-data-table-footer .cb-pagination .v-pagination__navigation i {
    font-size: 16px !important;
    font-weight: 500;
    color: $color_app_bg_accent !important;
}

.cb-data-table-footer .cb-pagination .v-pagination__navigation--disabled i {
    font-size: 18px !important;
    color: $color_app_contrast_medium !important;
}

.cb-data-table-footer .cb-pagination .v-pagination__item--active {
    color: $color_app_bg_accent !important;
    font-weight: 500;
    font-size: 16px !important;
}

.cb-items-per-page-selector {
    width: 60px;
    max-width: 60px;
}
</style>
<docs>

Uso básico del componente
```vue
<DataTableFooter
  :items-per-page="5"
  :page="1"
  :result-time="0.0012929439544677734"
  :total-items="50"
  model="Ejemplo"
  @changePage=""
  @refresh=""
/>
```
</docs>
