<template>
    <tr
      ref="row"
      class="cb-data-table-row cb-row-with-copy"
    >
        <td
          v-for="(field, index) in fields.filter(f => !f.hide)"
          :key="index"
          :class="index == 0 ? item['class']:''"
          :style="getFieldStyle(index,field)"
          class="text-truncate"
        >
            <v-tooltip
              v-if="index == 0 && item['icon'] != null"
              bottom
            >
                <template v-slot:activator="{ on }">
                    <v-icon
                      :color="item['icon'].color"
                      class="mr-1"
                      x-small
                      v-on="on"
                    >
                        {{item['icon'].name}}
                    </v-icon>
                </template>
                <span v-html="item['icon'].text"></span>
            </v-tooltip>
            <span
              :title="item[field.value]"
            >
          <span v-if="field.value == 'actions'">
            <MenuModelA :actions="rowActions"
                        :icon="$icons.I_ACTIONS"
                        @actionLaunched="actionLaunched"
            />
          </span>
          <span v-else-if="item[field.value] != null">
            <a v-if="field.type == 'link'"
               @click="actionLaunched($events.DATA_TABLE_OPEN)">
            {{item[field.value]}}
            </a>
            <span v-else-if="field.type == 'image'">
              <img :src="item[field.value]"
                   :title="item[field.value]"
                   alt=""
                   class="cb-data-table-image"
                   height="20px"
                   width="20px"
              />
            </span>
             <span v-else-if="field.type == 'timestamp'">
                <span v-if="item[field.value] == null"
                      class="cb-text-descriptor">
                Sin registro
                </span>
                <span v-else>
                    <span class="small">
                    {{parseInt(item[field.value]) | moment('DD/MM/YY')}}
                    </span>
                <span class="font-weight-medium"> {{parseInt(item[field.value]) | moment('HH:mm:ss')}}</span>
                <span class="cb-text-descriptor ml-2 font-italic"> {{parseInt(item[field.value]) | moment('from')}}
                </span>
              </span>
            </span>
             <span v-else-if="field.type == 'timestamp_date'">
                <span v-if="item[field.value] == null"
                      class="cb-text-descriptor">
                Sin registro
                </span>
                <span v-else>
                    <span class="small">
                    {{parseInt(item[field.value]) | moment('DD/MM/YY')}}
                    </span>
                <span class="cb-text-descriptor ml-2 font-italic"> {{parseInt(item[field.value]) | moment('from')}}
                </span>
              </span>
            </span>
             <span v-else-if="field.type == 'seconds'">
              <span v-if="item[field.value] == null" class="cb-text-descriptor">
                Sin registro
              </span>
              <span v-else>
              <span
                class="small">{{getSecondsDate(item[field.value]) | moment('DD/MM/YY')}}
              </span>
                <span class="font-weight-medium">{{getSecondsDate(item[field.value]) | moment('HH:mm:ss')}}</span>
                <span class="cb-text-descriptor ml-2 font-italic">{{getSecondsDate(item[field.value]) | moment('from')}}
                </span>
              </span>
            </span>
            <span v-else-if="field.type=='email_name' && item[field.value].split('@').length == 2">
              <v-icon :color="helpers.stringToColor(item[field.value])"
                      class="mr-1"
                      small
                      style="margin-top: -2px;">
                person
              </v-icon>
              <span>
                {{item[field.value].split('@')[0]}}
              </span>
            </span>
            <span v-else-if="field.type=='icon_color'" :title="item[field.value].name">
              <v-icon :color="item[field.value].color"
                      class="mr-1"
                      small
                      style="margin-top: -2px;">
                {{item[field.value].icon}}
              </v-icon>
            </span>
            <span v-else-if="field.type=='boolean'">
              <v-icon :color="item[field.value] == 1 ? 'success':'contrast_medium'"
                      class="mr-1"
                      small
                      style="margin-top: -2px;">
                {{item[field.value] == 1 ? 'check_circle' : 'highlight_off'}}
              </v-icon>
              <span :class="item[field.value] == 1 ? 'success--text':'contrast_medium--text'"
                    class="font-weight-medium">
                  <span
                    v-if="field.extra && field.extra.boolValues">{{item[field.value] == 1 ? field.extra.boolValues[1] : field.extra.boolValues[0]}}</span>
                  <span v-else>{{item[field.value] == 1 ? 'Activado' : 'Desactivado'}}</span>
              </span>
            </span>
            <span v-else-if="field.type=='boolean_warning'">
              <v-icon :color="item[field.value] == 1 ? 'warning':'contrast_medium'"
                      class="mr-1"
                      small
                      style="margin-top: -3px;">
                {{item[field.value] == 1 ? 'warning' : 'check'}}
              </v-icon>
              <span class="font-weight-medium my-auto">
                  <span v-if="field.extra && field.extra.boolValues"
                        :class="item[field.value] == 1 ? 'warning--text':'contrast_medium--text'"
                        class="">{{item[field.value] == 1 ? field.extra.boolValues[1] : field.extra.boolValues[0]}}</span>
                  <span v-else :class="item[field.value] == 1 ? 'warning--text':'contrast_medium--text'"
                        class="">{{item[field.value] == 1 ? 'Sí' : 'No'}}</span>
              </span>
            </span>
            <span v-else>
            {{item[field.value]}}
            </span>
          </span>
          <span v-else
                class="cb-text-descriptor"
          >Vacío</span>
          <v-icon v-if="!field.hide_copy && !noCopyFields.includes(field.type) && field.value != 'actions'"
                  class="cb-copy-cell-val"
                  title="copiar celda"
                  @click="helpers.copyCellVal($refs.row, item[field.value])"
          >
            copy_all
          </v-icon>
        </span>
        </td>
    </tr>
</template>

<script>
import helpers from '@/plugins/helpers'
import helpers_resolution from '@/plugins/helpers_resolution'
import MenuModelA from '@/components/repository/buttons/MenuModelA.vue'


export default {
    name: 'StandardTableRow',
    data() {
        return {
            helpers,
            helpers_resolution,
            noCopyFields: ['actions', 'icon_color', 'boolean', 'boolean_warning', 'timestamp', 'timestamp_date', 'seconds', 'email_name']
        }
    },
    props: {item: Object, visualOptions: Object, fields: Array},
    computed: {
        rowActions() {
            let actions = []
            let commonActions = this.fields.find(f => f.value == 'actions').actions()
            if (commonActions != null) {
                actions.push(...commonActions)
            }
            if (this.item.actions != null) {
                actions.push(...this.item.actions)
            }
            return actions
        }
    },
    components: {MenuModelA},
    methods: {
        getSecondsDate(val) {
            return this.$moment().add(val, 's').valueOf()
        },
        getFieldStyle(index, field) {
            let style = ''
            if (field.value == 'actions') {
                style += ';padding: 0 !important;text-align:center'
            } else {
                style += helpers_resolution.getColWidth(field.width)
            }
            if (index == 0 && this.item['style']) {
                style += this.item['style']
            }
            return style
        },
        actionLaunched(action) {
            this.$emit('actionLaunched', {
                item: this.item,
                action
            })
        }
    },
    filters: {}
}
</script>
