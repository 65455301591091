import {P_PROTOCOL_NOTARIAL_DEPOSIT} from '@/constants/group_permissions'
import icons from "@/constants/icons";
import {MANAGEMENT} from "@/constants/categories";

//const ProcessManagement = () => import(/* webpackChunkName: "ProcessManagement"*/ '@/views/management/ProcessManagement.vue')
const NotarialDeposit = () => import(/* webpackChunkName: "ProcessManagement"*/ '@/views/management/NotarialDeposit.vue')

const ROOT = '/management/'
const CATEGORY = MANAGEMENT
export default [
    /*{
        path: ROOT + 'processes',
        name: 'processes',
        component: ProcessManagement,
        meta: {
            perm: P_PROCESSES,
            title: 'Gestión de procesos',
            title_short: 'Procesos',
            navigable: true,
            category: CATEGORY,
            icon: 'work',
            requiresAuth: true
        },
    },*/
    {
        path: ROOT + 'notarial_deposit',
        name: 'notarial_deposit',
        component: NotarialDeposit,
        meta: {
            perm: P_PROTOCOL_NOTARIAL_DEPOSIT,
            icon: icons.I_NOTARIAL_DEPOSIT,
            title: 'Depósito Notarial',
            title_short: 'D. Notarial',
            navigable: true,
            category: CATEGORY,
            requiresAuth: true
        }
    },
]
